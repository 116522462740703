import { Component, ElementRef, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { commonService } from '../services/commonService';
import { userService } from 'src/app/services/userservice';
import { jwtDecode } from 'jwt-decode';
import { Renderer2 } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { PaginatorModule } from 'primeng/paginator';
declare var $: any;
interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}
@Component({
  selector: 'app-reports-two',
  templateUrl: './reports-two.component.html',
  styleUrls: ['./reports-two.component.scss'],
})
export class ReportsTwoComponent {
  totalClosedJobsCount: any;
  userId: any;
  getReportData: any = [];
  userData: any;
  reportBrandName: any;
  reportCountry: any;
  countryBrandName: any;
  countryWiseReportsSearchh: any;
  rejectedCount: any;
  constructor(
    injector: Injector,
    private route: ActivatedRoute,
    public router: Router,
    public userService: userService,
    public commonservice: commonService,
    private renderer: Renderer2,
    private el: ElementRef,
    private notifyService: NotificationService,
  ) { }
  ngOnInit() {
    let userInfo = this.userService.getUserId();
    this.userData = jwtDecode(userInfo);
    // this.uid = this.userData.UserId
    this.userId = this.userData.UserId;
    this.GetJobsReport();
    this.getReportsDataa(this.userId);
    this.getInterviewReportsData();
    this.GetCountryReportDataa();
    this.GetResumeReportDataa();
    this.getJobReportsBasedOnCOuntry(this.userId);
  }
  total = '120';
  reportsData: any[] = [
    // { giis: '66', owis: '16', witty : '1', harrods : '5', dwight : '8', glendale : '1', regent : '', domuschola : '', CIS : '', Vikaasa : '', total :'98' },
    { brand: 'GIIS', brandSccore: '66' },
  ];
  backToReports() {
    this.reportsTwoMainScreen = true;
    this.brandWiseActiveJobsScreen = false;
    this.brandWiseClosedJobsScreen = false;
    this.applicantsCountScreen = false;
    this.applicantsCountryCountScreen = false;
    this.resumeBankScreen = false;
    this.websiteAppliedScreen = false;
    this.reportsTable = true;
    this.websiteUploadedResumeScreen = false;
    this.JobsWiseActiveJobsScreen = false;
    this.toggleBrandwiseTable = false;
    this.toggleNewReportsTableSection = false;
    this.activeCardIndex1 = -1;
    this.activeCardIndex2 = -1;
    this.activeCardIndex3 = -1;
    this.activeCardIndex4 = -1;
  }
  brandWiseActiveJobsScreen: boolean = false;
  reportsTwoMainScreen: boolean = true;
  brandWiseClosedJobsScreen: boolean = false;
  applicantsCountScreen: boolean = false;
  applicantsCountryCountScreen: boolean = false;
  JobsWiseActiveJobsScreen: boolean = false;
  toggleBrandWiseActiveJobsScreen() {
    this.reportsTwoMainScreen = false;
    this.brandWiseActiveJobsScreen = true;
    this.brandWiseClosedJobsScreen = false;
    this.applicantsCountScreen = false;
    this.applicantsCountryCountScreen = false;
    this.resumeBankScreen = false;
    this.websiteAppliedScreen = false;
    this.websiteUploadedResumeScreen = false;
    this.reportsTable = false;
    this.JobsWiseActiveJobsScreen = false;
    // this.router.navigateByUrl('brandwise-active-jobs');
  }

  toggleJobsWiseActiveJobsScreen() {
    this.reportsTwoMainScreen = false;
    this.brandWiseActiveJobsScreen = false;
    this.brandWiseClosedJobsScreen = false;
    this.applicantsCountScreen = false;
    this.applicantsCountryCountScreen = false;
    this.resumeBankScreen = false;
    this.websiteAppliedScreen = false;
    this.websiteUploadedResumeScreen = false;
    this.reportsTable = false;
    this.JobsWiseActiveJobsScreen = true;
    this.toggleBrandwiseTable = false;
  }

  toggleBrandWiseClosedJobsScreen() {
    this.reportsTwoMainScreen = false;
    this.brandWiseActiveJobsScreen = false;
    this.brandWiseClosedJobsScreen = true;
    this.applicantsCountScreen = false;
    this.applicantsCountryCountScreen = false;
    this.resumeBankScreen = false;
    this.websiteAppliedScreen = false;
    this.websiteUploadedResumeScreen = false;
    this.reportsTable = false;
    this.JobsWiseActiveJobsScreen = false;
    // this.router.navigateByUrl('brandwise-active-jobs');
  }

  toggleApplicantsCountScreen() {
    this.reportsTwoMainScreen = false;
    this.brandWiseActiveJobsScreen = false;
    this.brandWiseClosedJobsScreen = false;
    this.applicantsCountScreen = true;
    this.applicantsCountryCountScreen = false;
    this.resumeBankScreen = false;
    this.websiteAppliedScreen = false;
    this.websiteUploadedResumeScreen = false;
    this.reportsTable = false;
    this.JobsWiseActiveJobsScreen = false;
  }
  toggleApplicantsCountryScreen() {
    this.reportsTwoMainScreen = false;
    this.brandWiseActiveJobsScreen = false;
    this.brandWiseClosedJobsScreen = false;
    this.applicantsCountScreen = false;
    this.applicantsCountryCountScreen = true;
    this.resumeBankScreen = false;
    this.websiteAppliedScreen = false;
    this.websiteUploadedResumeScreen = false;
    this.reportsTable = false;
    this.JobsWiseActiveJobsScreen = false;
  }
  totalReportsDataActive: any[] = [];
  totalReportsDataClosed: any[] = [];
  totalActiveJobs: any;
  totaClosedJobs: any;
  reportsShowLoader: boolean = false;
  totalPositionsArray = [];
  totalNumberOfPositions: any;
  totalNumberOfJobs: any;
  closedJobsPositions: any;
  totalClosedBrandCount: any;
  activeTotalPositions: any;
  newTotalClosedJobsCount: any;
  getReportsDataa(userId: any) {
    this.reportsShowLoader = true;
    this.commonservice.GetReportsData(userId).subscribe((data: any) => {
      // console.log(data);
      if (data) {
        this.reportsShowLoader = false;
      }

      this.totalReportsDataActive = data.activeJobs;
      this.totalPositionsArray = data.activeposition;
      this.totalActiveJobs = data.totalActiveJobsCount;
      this.totalReportsDataClosed = data.closedJobs;
      this.totaClosedJobs = data.totalClosedJobsCount;
      this.totalNumberOfPositions = data.totalActiveJobsCount.noofposition;
      this.totalNumberOfJobs = data.totalActiveJobsCount;
      this.closedJobsPositions = data.closedJobs.noofposition;
      this.totalClosedBrandCount = data.totalClosedJobsCount;
      this.totalClosedJobsCount = data.totalClosedJobsCount;
      this.activeTotalPositions = data.totalNoOfPositions;
      this.newTotalClosedJobsCount = data.totalNoOfClosedPositions;
      // console.log(data, '.....................');
    });
  }
  interviewCountArrayMain: any[] = [];
  interviewCountArray: any[] = [];
  applicantsCountTotal: any;
  showLoader: boolean = false;
  allcount: any;
  interviewcount: any;
  newCount: any;
  offeredcount: any;
  postOfferCOunt: any;
  preOffercount: any;
  screeningcount: any;
  getInterviewReportsData() {
    this.commonservice
      .GetApplicantStageWiseCountReport(this.userId)
      .subscribe((data: any) => {
        // this.showLoader = true;
        // if (data){
        //   this.showLoader = false;
        // }
        this.interviewCountArrayMain = data;
        // this.interviewCountArray = data.stage;
        // this.applicantsCountTotal = data.totalApplicantCount;
        // console.log(
        //   this.interviewCountArrayMain,
        //   'interview reportsssssssssssssssssssssssssss'
        // );
        this.allcount = data.allcount;
        this.interviewcount = data.interview;
        this.newCount = data.new;
        this.offeredcount = data.offered;
        this.postOfferCOunt = data.postOffer;
        this.preOffercount = data.preOffer;
        this.screeningcount = data.screening;
        this.rejectedCount=data.rejected
        // console.log(this.allcount, "nnnnnnnnnnnnpppppppppppppppppp");
      });
  }
  resumeBankScreen: boolean = false;
  toggleResumeBankScreen() {
    this.reportsTwoMainScreen = false;
    this.brandWiseActiveJobsScreen = false;
    this.brandWiseClosedJobsScreen = false;
    this.applicantsCountScreen = false;
    this.applicantsCountryCountScreen = false;
    this.resumeBankScreen = true;
    this.websiteAppliedScreen = false;
    this.websiteUploadedResumeScreen = false;
    this.reportsTable = false;
    this.JobsWiseActiveJobsScreen = false;
  }
  countryWiseReports: any = [];
  totalCountryAppCount: any;
  GetCountryReportDataa() {
    this.commonservice
      .GetCountryReportData(this.userId)
      .subscribe((data: any) => {
        // console.log(data, "countryyyyyyyyyyyyyyyyyyyy");
        this.countryWiseReports = data.applicantcountrywise;
        this.totalCountryAppCount = data.totalapplicantscount;
      });
  }
  websiteAppliedScreen: boolean = false;
  toggleWebsiteAppliedScreen() {
    this.reportsTwoMainScreen = false;
    this.brandWiseActiveJobsScreen = false;
    this.brandWiseClosedJobsScreen = false;
    this.applicantsCountScreen = false;
    this.applicantsCountryCountScreen = false;
    this.resumeBankScreen = false;
    this.websiteAppliedScreen = true;
    this.websiteUploadedResumeScreen = false;
    this.reportsTable = false;
    this.JobsWiseActiveJobsScreen = false;
  }
  websiteUploadedResumeScreen: boolean = false;
  toggleWebsiteUploadedResumeScreen() {
    this.reportsTwoMainScreen = false;
    this.brandWiseActiveJobsScreen = false;
    this.brandWiseClosedJobsScreen = false;
    this.applicantsCountScreen = false;
    this.applicantsCountryCountScreen = false;
    this.resumeBankScreen = false;
    this.websiteAppliedScreen = false;
    this.websiteUploadedResumeScreen = true;
    this.reportsTable = false;
    this.JobsWiseActiveJobsScreen = false;
  }
  resumeData = [];
  resumeTotalCount: any;
  GetResumeReportDataa() {
    this.commonservice.GetResumeReportData().subscribe((data: any) => {
      this.resumeData = data.uploadResume;
      this.resumeTotalCount = data.totalResumesCount;
    });
  }
  getReport: any = [];
  reportsLoader: any;
  reportsStatus: boolean = false;
  GetJobsReport() {
    // this.reportsLoader = true;
    this.commonservice.GetJobsReport(this.userId).subscribe((data: any) => {
      this.getReport = data;
      if (data) {
        this.reportsLoader = false;
      }
      // console.log(this.getReport);
    });
  }


  GetJobsReportsearchItem(val: any) {
    this.getReport = []
    this.commonservice.GetJobsReportsearchItem(val).subscribe((data: any) => {
      if (data) {
        this.getReport = data;
        this.reportsLoader = false;
      }
      // console.log(this.getReport);
    });
  }


  blueCrossMark: boolean = false;
  reportsSearchh: any;
  brandActiveReportsSearchh: any;
  // showCross(val: any) {
  //   if (this.reportsSearchh) {
  //     this.blueCrossMark = true;
  //   } else this.blueCrossMark = false;
  //   if (this.brandActiveReportsSearchh) {
  //     this.blueCrossMark = true;
  //   } else this.blueCrossMark = false;
  //   if (this.countryWiseReportsSearchh) {
  //     this.blueCrossMark = true;
  //   } else this.blueCrossMark = false;


  // }
  showCross() {
    this.blueCrossMark = !!this.reportsSearchh || !!this.brandActiveReportsSearchh || !!this.countryWiseReportsSearchh;
  }
  removeValue() {
    this.blueCrossMark = false;
    this.reportsSearchh = '';
    this.brandActiveReportsSearchh = '';
    this.countryWiseReportsSearchh = '';
    this.GetJobsReport();
  }

  reportsTable: boolean = true;

  // Initial sorting order for 'status' column
  statusSortOrder: 'asc' | 'desc' = 'asc';
  dateArrow: boolean = true;
  sortColumn(column: string) {
    this.dateArrow = !this.dateArrow;
    if (column === 'status') {
      // Toggle sorting order for 'status' column
      this.statusSortOrder = this.statusSortOrder === 'asc' ? 'desc' : 'asc';
      this.getReport.sort((a, b) => this.statusSort(a.status, b.status));
    } else {
      // For other columns, you can use a default sorting method
      this.getReport.sort((a, b) => (a[column] > b[column] ? 1 : -1));
    }
  }

  statusSortOrderr: 'asc' | 'desc' = 'asc';
  sortSchool(column: string) {
    this.dateArrow = !this.dateArrow;
    if (column === 'schools') {
      // Toggle sorting order for 'status' column
      this.statusSortOrderr = this.statusSortOrderr === 'asc' ? 'desc' : 'asc';
      this.getReport.sort((a: any, b: any) =>
        this.statusSort(a.school, b.school)
      );
    } else {
      // For other columns, you can use a default sorting method
      this.getReport.sort((a: any, b: any) => (a[column] > b[column] ? 1 : -1));
    }
  }

  private statusSort(statusA: string, statusB: string): number {
    const order =
      this.statusSortOrderr === 'asc'
        ? ['GIIS', 'DWIGHT', 'HARRODS']
        : ['HARRODS', 'DWIGHT', 'GIIS'];
    return order.indexOf(statusA) - order.indexOf(statusB);
  }
  jobwiseActiveJobs = [];
  jobwiseClosedJobs = [];
  jobssTotalNoOfPositions: any;
  totalActivecountryJobsCountt: any;
  totalClosedcountryJobsCountt: any;
  jobstotalNoOfPositionss: any;
  brand_count: any;
  totalNoOfPositionData: any;
  getJobReportsBasedOnCOuntry(userId: any) {
    this.commonservice.GetJobsReportOnCountry(userId).subscribe((data: any) => {
      this.jobwiseActiveJobs = data.activeJobs;

      this.jobwiseClosedJobs = data.closedJobs;
      this.jobssTotalNoOfPositions = data.totalNoOfPositions;
      this.totalActivecountryJobsCountt = data.totalActivecountryJobsCount;
      this.totalClosedcountryJobsCountt = data.totalClosedcountryJobsCount;
      this.jobstotalNoOfPositionss = data.totalNoOfPositions;
      this.totalNoOfPositionData = data.totalNoOfPosition;
    });
  }
  exceldata: any;
  downloadReportsActionModal: boolean = false;
  getExcelReports() {
    this.downloadReportsActionModal = true;
    
    setTimeout(() => {
      this.downloadReportsActionModal = false;
    }, 8000);
    // this.notifyService.showSuccess(
    //   'PDF Report Generating', 'Please Wait.'
    // );
    this.commonservice.GetReportExcel(this.userId).subscribe((res: any) => {
      const link = document.createElement('a');
      link.href = res.filePath;
      link.target = '_blank';  
      link.setAttribute('download', 'filename.pdf');  
      link.click();
    });
  }
  visible: boolean = false;

  showDialog() {
    this.visible = true;
  }

  tableSectionTwo: boolean = false;
  newCountryName: any;
  newBrandName: any;
  activeCardIndex1: number = -1;
  activeCardIndex3: number = -1;

  isActive1(index: number): boolean {
    return this.activeCardIndex1 === index;
  }
  country_name: any;
  activeJobsnew: any = [];
  brandna_name: any;
  toggleActiveCard1(index: number, name) {
    // alert('Please select');
    this.activeCardIndex1 = index;
    this.activeCardIndex2 = -1;
    // this.activeCardIndex1 = (this.activeCardIndex1 === index) ? -1 : index;
    // this.activeCardIndex3 = -1;
    // this.activeCardIndex4 = -1;
    this.brandna_name = name;

    for (var i = 0; i < this.totalReportsDataActive.length; i++) {
      console.log(this.totalReportsDataActive[i].brandName);
      if (this.totalReportsDataActive[i].brandName == name) {
        this.activeJobsnew = this.totalReportsDataActive[i].countryCounts;
      }
    }

    this.brandActiveReportsSearchh = '';
    // this.country_name
    // this.totalReportsDataActive
  }

  isActive2(index: number): boolean {
    return this.activeCardIndex2 === index;
  }
  closedjobapicount: any = [];
  toggleActiveCard2(name) {
    // alert(name)
    this.activeCardIndex2 = name;
    this.activeCardIndex1 = -1;
    // this.activeCardIndex1 = (this.activeCardIndex1 === index) ? -1 : index;
    // this.activeCardIndex3 = -1;
    // this.activeCardIndex4 = -1;
    this.brandna_name = name;

    for (var i = 0; i < this.totalReportsDataClosed.length; i++) {
      // console.log(this.totalReportsDataClosed[i].brandName);
      if (this.totalReportsDataClosed[i].brandName == name) {
        this.closedjobapicount = this.totalReportsDataClosed[i].countryCounts;
      }
    }
  }

  toggleNewReportsTableActivenew(valcountry: any, status: any) {
    this.brandActiveReportsSearchh = '';
    this.newCountryName = valcountry;
    if(valcountry && valcountry!=undefined){
      valcountry=valcountry
    }else{
      valcountry=""
    }
    this.tableSectionTwo = true;
    this.brandna_name = this.brandna_name;
    this.reportsLoader = true;
    this.getReportData = [];
    this.reportsShowLoaderTwo = true;
    this.toggleBrandwiseTable = false;
    this.statusInfo = status
    this.commonservice
      .GetActiveCloseCountryBrandJobsTrackerReport(
        this.userId,
        valcountry,
        1,
        this.brandna_name
      )
      .subscribe((data: any) => {
        if (data) {
          this.reportBrandName = data[0].school;
          this.reportCountry = data[0].country;
          this.getReportData = data;
          this.reportsShowLoaderTwo = false;
          this.toggleBrandwiseTable = true;
          this.reportsLoader = false;
          setTimeout(() => {
            const element = document.getElementById('sectionId');
            if (element) {
              element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
          }, 500);
        }
      });
  }


  GetActiveCloseCountryBrandJobsTrackerReportbysearchitems(val:any){
    this.getReportData=[]
    this.commonservice
    .GetActiveCloseCountryBrandJobsTrackerReportbysearchitems(val)
    .subscribe((res: any) => {
      this.newCountryWiseActiveTable = res;

    })

  }
  reportsShowLoaderNew : boolean = false;

  GetActiveCloseBrandJobsTrackerReportbysearchitems(val:any){
    this.getReportData=[];
    this.reportsShowLoaderNew = true;
    this.commonservice
    .GetActiveCloseBrandJobsTrackerReportbysearchitems(val)
    .subscribe((res: any) => {
      this.getReportData = res;
      if (res) {
        this.reportsShowLoaderNew = false;
      }

    })

  }

  isActive3(index: number): boolean {
    return this.activeCardIndex3 === index;
  }
  toggleActiveCard3(index: number) {
    // this.activeCardIndex3 = (this.activeCardIndex3 === index) ? -1 : index;
    this.activeCardIndex3 = index;
    this.activeCardIndex4 = -1;
  }
  activeCardIndex4: number = -1;
  isActive4(index: number): boolean {
    return this.activeCardIndex4 === index;
  }
  toggleActiveCard4(index: number) {
    this.activeCardIndex4 = index;
    this.activeCardIndex3 = -1;
  }
  countIndnewwww(x: any, status: any, index: any, countrynamee: any) {
    // if (this.activeCardIndex3 === index) {
    //   this.activeCardIndex3 = -1;
    // } else {
    //   this.activeCardIndex3 = index;
    // }
    this.newCountryName = countrynamee;

    // this.getCountryWisePopUptableData(this.userId, this.newCountryName, 2);
    // this.toggleNewReportsTableSection = false;
    // this.tableSectionTwo = false;

    var data = this.jobwiseClosedJobs;
    if (index >= 0 && index < data.length) {
      this.closedJobs = data[index].brandCounts;
      this.brand_count = data[index].brandCounts;

      // this.newBrandName = this.closedJobs[0].brandName;
    }
    var data1 = this.jobwiseActiveJobs;
    // console.log(data1, 'shabb');
    if (index >= 0 && index < data1.length) {
      this.brand_count = data1[index].brandCounts;

      // this.newBrandName = this.closedJobs[0].brandName;
    }
  }

  closedJobs: any;
  statusnewone: any;
  closeInd(x: any, status: any, index: any, countrynamee: any) {
    this.newCountryName = countrynamee;
    this.statusnewone = status;
    this.getCountryWisePopUptableData(this.userId, countrynamee, status, index);
    this.toggleNewReportsTableSection = false;
    this.tableSectionTwo = false;
    var data = this.jobwiseClosedJobs;
    if (index >= 0 && index < data.length) {
      this.closedJobs = data[index].brandCounts;
      this.newBrandName = this.closedJobs[0].brandName;
    }
  }
  newCountryWiseActiveTable = [];
  countryReportName: any;
  onViewOverallReport(x: any, param1: number, newCountryName: any, index: number) {
    this.getCountryWisePopUptableData(x, param1, newCountryName, index);
    this.toggleActiveCard3(index);

    $('#staticBackdrop').modal('hide');
  }
  getCountryWisePopUptableData(
    data1: any,
    data2: any,
    status: any,
    index: any
  ) {
    // if (this.activeCardIndex3 === index) {
    //   this.activeCardIndex3 = -1;
    // } else {
    //   this.activeCardIndex3 = index;
    // }
    this.statusInfo = data2
    this.tableSectionTwo = true;
    this.toggleNewReportsTableSection = true;

    this.newCountryName = status;
    this.newBrandName = '';
    if (data2 == 1) {
      this.tableSectionTwo = true;
    } else if (data2 == 2) {
      this.tableSectionTwo = false;
    }
    this.commonservice
      .getCountryWisePopUptableData(this.userId, status, data2)
      .subscribe((data: any) => {
        this.countryReportName = data[0].country;
        this.countryBrandName = '';
        this.newCountryWiseActiveTable = data;
        this.reportsLoader = true;
        // setTimeout(() => {
          const element = document.getElementById('sectionId2');
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        // }, 500);
        if (data) {
          this.reportsLoader = false;
        }
      });
      $('#staticBackdrop').modal('hide');
  }
  toggleBrandwiseTable: boolean = false;
  reportsShowLoaderTwo: boolean = false;
  activeCardIndex: number = -1;
  activeCardIndex2: number = -1;
  brandInfoName: any;
  statusInfo: any;
  brandDataInfo(event: any, status: any, i: any) {
    this.statusInfo = status;

    // if (status == '1') {
    //   if (this.activeCardIndex === i) {
    //     this.activeCardIndex = -1;
    //     this.activeCardIndex2 = i;
    //   } else {
    //     this.activeCardIndex = i;
    //     this.activeCardIndex2 = -1;
    //   }
    // }
    //  if (status == '2') {
    //   if (this.activeCardIndex2 === i) {
    //     this.activeCardIndex2 = -1;
    //     this.activeCardIndex = i;
    //   } else {
    //     this.activeCardIndex2 = i;
    //     this.activeCardIndex = -1;
    //   }
    // }

    this.reportsShowLoaderTwo = true;
    var bName = event.brandName;
    var user = this.userId;
    this.tableSectionTwo = true;
    this.getReportData = [];
    this.toggleBrandwiseTable = false;
    this.brandna_name = event.brandName;
    this.newCountryName = '';
    if (status == 1) {
      this.tableSectionTwo = true;
    } else if (status == 2) {
      this.tableSectionTwo = false;
    }

    this.commonservice
      .GetActiveCloseBrandJobsTrackerReport(user, bName, status)
      .subscribe((res: any) => {
        this.reportBrandName = res[0].school;
        this.reportCountry = '';
        this.toggleBrandwiseTable = true;
        this.getReportData = res;
        if (res) {
          this.reportsShowLoaderTwo = false;
          setTimeout(() => {
            const element = document.getElementById('sectionId');
            if (element) {
              element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
          }, 500);
        }
      });
  }
  toggleNewReportsTableSection: boolean = false;
  toggleNewReportsTable(val: any, status: any) {
    this.reportsLoader = true;
    this.tableSectionTwo = true;
    this.toggleNewReportsTableSection = true;
    this.newBrandName = val;
    this.statusInfo = status
    this.commonservice
      .GetActiveCloseCountryBrandJobsTrackerReport(
        this.userId,
        this.newCountryName,
        status,
        val
      )
      .subscribe((data: any) => {
        this.countryBrandName = data[0].school;
        this.countryReportName = data[0].country;
        this.newCountryWiseActiveTable = data;
        if (data) {
          this.reportsLoader = false;
          setTimeout(() => {
            const element = document.getElementById('sectionId2');
            if (element) {
              element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
          }, 500);
        }
      });

    // this.newCountryWiseActiveTable = this.newCountryWiseActiveTable.filter((ele) => ele.school == val);
  }

  toggleNewReportsTableActivclosed(valcountry: any, status: any) {
    this.newCountryName = valcountry;
    this.reportsLoader = true;
    this.toggleBrandwiseTable = true;
    this.tableSectionTwo = false;
    this.brandna_name = this.brandna_name;
    this.statusInfo = status

    this.commonservice
      .GetActiveCloseCountryBrandJobsTrackerReport(
        this.userId,
        valcountry,
        status,
        this.brandna_name
      )
      .subscribe((data: any) => {
        if (data) {
          this.reportCountry = data[0].country;
          this.reportBrandName = data[0].school;
          this.getReportData = data;
          this.reportsLoader = false;
          setTimeout(() => {
            const element = document.getElementById('sectionId');
            if (element) {
              element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
          }, 500);
        }
      });
  }

  toggleNewReportsTableclosed(val: any, status: any) {
    this.toggleNewReportsTableSection = true;
    this.tableSectionTwo = false;
    this.newBrandName = val;
    this.statusInfo = status
    this.commonservice
      .GetActiveCloseCountryBrandJobsTrackerReport(
        this.userId,
        this.newCountryName,
        status,
        val
      )
      .subscribe((data: any) => {
        this.countryBrandName = data[0].school;
        this.countryReportName = data[0].country;
        this.newCountryWiseActiveTable = data;
      });

    // this.newCountryWiseActiveTable = this.newCountryWiseActiveTable.filter((ele) => ele.school == val);
    setTimeout(() => {
      const element = document.getElementById('sectionId2');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 500);
  }
  toggleSingleCountryWiseReports() {
    // this.newCountryWiseActiveTable=[]
    this.toggleNewReportsTableSection = true;
    // alert('Toggle');
  }
  generateReport: boolean = false;
  toggleGeneratereport() {
    this.generateReport = !this.generateReport;
    this.reportsLoader = true;
    if (this.generateReport) {
      this.commonservice.GetJobsReport(this.userId).subscribe((data: any) => {
        this.getReport = data;
        if (data) {
          this.reportsLoader = false;
          setTimeout(() => {
            const element = document.getElementById('sectionId');
            if (element) {
              element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
          }, 500);
        }
      });
    } else {
      this.getReport = [];
      this.generateReport = false;
      this.reportsLoader = false;
    }
  }
  commaSeperated(data: any) {
    var nameArr = data.split(',');
    var obj = nameArr;
    return obj;
  }
  getCloseExcelReports() {
    this.notifyService.showSuccess(
      'PDF Report Generating', 'please wait.'
    );
    this.commonservice.GetBrandCountryActiveCloseJobsReportExcel(this.userId, this.countryBrandName, this.countryReportName, this.statusInfo).subscribe((res: any) => {
      const link = document.createElement('a');
      link.href = res.filePath;
      link.target = '_blank'; // Open the link in a new tab
      link.setAttribute('download', 'filename.pdf'); // Force download the file with a specific name
      link.click();
    })
  }
  getActiveExcelReports() {
    this.notifyService.showSuccess(
      'PDF Report Generating', 'please wait.'
    );
    this.commonservice.GetBrandCountryActiveCloseJobsReportExcel(this.userId, this.reportBrandName, this.reportCountry, this.statusInfo).subscribe((res: any) => {
      const link = document.createElement('a');
      link.href = res.filePath;
      link.target = '_blank'; // Open the link in a new tab
      link.setAttribute('download', 'filename.pdf'); // Force download the file with a specific name
      link.click();
    })
  }

  first: number = 0;
rows: number = 10;
itemsPerPageActiveTwo: number = 10;
totalRecordsActiveTwo: number = 100;

onPageChangeActiveTwo(event: PageEvent) {
    this.first = event.first;
    this.itemsPerPageActiveTwo = event.rows;
}
}
