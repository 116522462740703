import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { BehaviorSubject, Observable } from 'rxjs';
// import { environment } from '../../app/environments/environment';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonServiceService {

  headers = new HttpHeaders().set('content-type', 'application/json').set('Access-Control-Allow-origin', '*').set('Cache-Control', 'no-cache')
    .set('Pragma', 'no-cache');
  userDetails: any;

  constructor(private http: HttpClient) {

    let user = localStorage.getItem('userDetails');
    if (user != null) {
      // let userDetails = JSON.parse(user);
      // this.userId = userDetails.userId.toString();
      // this.headers = this.headers.append('userId', this.userId);
      this.userDetails = jwtDecode(user)
      let userId = this.userDetails.UserId
      this.headers = this.headers.append('userId', userId);

    }
  }

  GetPublishedJobNames(userId:any) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetPublishedJobNames?userId=${userId}`, { headers: this.headers });
  }

GetPublishedJobDetails() {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetPublishedJobDetails`, { headers: this.headers });
  }
getArvicheJobList() {
    return this.http.get(`${environment.apiUrl}/api/Master/GetJobNames`, { headers: this.headers });
  }
  GetJobCampus(id:any) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetJobCampus?jobId=${id}`, { headers: this.headers });
  }
  GetCategoryType(isManagesettings = false) {
    return this.http.get(`${environment.apiUrl}/api/Master/GetCategoryType?status=${isManagesettings}`, { headers: this.headers });
  }
  GetSkillNameById(data:any) {
    return this.http.get(`${environment.apiUrl}/api/Master/GetSkillNameById?id=${data}`, { headers: this.headers });
  }
  getIJPJobList(userid) {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetIJPPublishedJobNames?userId=${userid}`, { headers: this.headers });
  }
}
